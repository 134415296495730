<template>
  <app-layout>
    <v-text :show-privacy="showPrivacy" :show-terms="showTerms" />
    <div class="page__card-footer">
      <button class="button button_secondary page__card-footer-button" @click="setPassed">
        <span>I agree</span>
        <span class="icon icon__arrow icon__arrow_right" />
      </button>
    </div>
  </app-layout>
</template>

<script>
import RouteMixin from "@/mixins/routing";
import logVisiting from "@/mixins/logVisiting";
import AppLayout from "@/components/appLayout";
import vText from "./components/text";
import {
  ROUTE_CONSENT_CONSUMERAPP,
  ROUTE_ABOUT,
  POPUP_PRIVACY,
  POPUP_TERMS,
} from "@/constants";

export default {
  name: "Consent",
  components: { AppLayout, vText },
  mixins: [RouteMixin, logVisiting],

  inject: {
    showModal: { default: () => {} },
  },

  data: () => ({
    currentRoute: ROUTE_CONSENT_CONSUMERAPP,
    nextRoute: ROUTE_ABOUT,
  }),

  methods: {
    showPrivacy() {
      this.showModal(POPUP_PRIVACY);
    },

    showTerms() {
      this.showModal(POPUP_TERMS);
    },
  },
};
</script>
